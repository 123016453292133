<template lang='pug'>
v-card(v-if="Object.keys(statement).length").pa-3
  v-card-title.d-flex.justify-space-between.w-100
    div {{ ($t(`statement-package-isc-${Object.keys(statement?.behavior)[0]}`, { id: statement?.id })).toUpperCase() }}
    ControlButtons(:controlButtons="controlButtons").justify-end
  v-progress-linear(v-if="isLoading" indeterminate color="cyan")
  v-card-text.text-left
    v-divider
    Info(v-if="statement?.behavior?.viewInfoBlock" :statement="statement")
    Create(v-if="statement?.behavior?.viewCreateBlock" :statement="statement")
    Reject(v-if="statement?.behavior?.viewRejectBlock" :statement="statement")
</template>
<script>
import { viewDetailedComponent } from '@/mixins/main'
import { checkAccess } from '@/mixins/permissions'
import { mapActions, mapState } from 'vuex'
import { STATUSES_STATEMENT_CONSTANTS } from '@/configs/constants'
export default {
  name: 'Document',
  components: {
    ControlButtons: () => import('@/components/atoms/ControlButtons'),
    Info: () => import('./Info.vue'),
    Create: () => import('./Create.vue'),
    Reject: () => import('./Reject.vue'),
    ViewPhotoList: () => import('@/components/atoms/ViewPhotoList')
  },
  data () {
    return {
      controlButtons: [
        {
          id: 1,
          name: 'mdi-account-circle-outline',
          to: () => ({ name: 'position-statements', params: { id: this.statement.sailor.id } }),
          color: '#42627e',
          tooltip: 'documentIconControl.goToSailor',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: '#42627e', outlined: false }
        },
        {
          id: 2,
          name: 'mdi-information-outline',
          action: () => viewDetailedComponent(this.statement, 'viewInfoBlock'),
          color: '#42627e',
          tooltip: 'documentIconControl.info',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: '#42627e', outlined: false }
        },
        {
          id: 3,
          name: 'mdi-plus-outline',
          action: () => viewDetailedComponent(this.statement, 'viewCreateBlock'),
          checkAccess: () => checkAccess('backOffice') &&
          [STATUSES_STATEMENT_CONSTANTS.PACKAGE.CREATED].includes(this.statement.status_document),
          color: 'green',
          tooltip: 'documentIconControl.createPacket',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: 'green', outlined: false }
        },
        {
          id: 4,
          name: 'mdi-file-remove-outline',
          action: () => viewDetailedComponent(this.statement, 'viewRejectBlock'),
          checkAccess: () => checkAccess('backOffice') &&
          [STATUSES_STATEMENT_CONSTANTS.PACKAGE.CREATED].includes(this.statement.status_document),
          color: 'error',
          tooltip: 'documentIconControl.reject',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: 'green', outlined: false }
        },
        {
          id: 5,
          name: 'mdi-close',
          action: () => this.$router.go(-1),
          color: 'black',
          tooltip: 'documentIconControl.close',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: 'green', outlined: false }
        }
      ]
    }
  },
  computed: {
    ...mapState({
      statement: state => state.statement.statementsPackageISCById,
      isLoading: state => state.statement.isLoading
    })
  },
  mounted () {
    this.getData()
  },
  methods: {
    ...mapActions(['getStatementPackageISCById']),
    async getData () {
      this.getStatementPackageISCById({ ...this.$route.params })
    }
  }
}
</script>
